@font-face {
  font-family: "Pokemon Font";
  font-style: normal;
  font-weight: normal;
  src: local("Pokemon Font"), url("../pokemon.woff") format("woff");
}
h1,
h3,
p,
button {
  font-family: "Pokemon Font";
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 3px;
  background: -webkit-linear-gradient(rgb(51, 50, 50), rgb(14, 49, 66));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
h1,
h3 {
  padding: none;
  margin: 0;
  line-height: 3rem;
}
img {
  width: 25vw;
  height: auto;
}
.header {
  position: fixed;
  top: 0;
  margin-bottom: 5px;
}
.container, .card {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  width: 100%;
  height: 100%;
  min-width: 320px;
  min-height: 568px;
}
.card {
  width: 58vw;
}
.left, .right {
  position: fixed;
  bottom: 50vh;
}
.left {
  border-radius: 72% 28% 26% 74% / 49% 51% 49% 51%;
  left: 10px;
}
.right {
  border-radius: 28% 72% 69% 31% / 49% 51% 49% 51%;
  right: 10px;
}
.footer {
  position: fixed;
  bottom: 0;
}

@media only screen and (max-width: 950px) {
  h1 {
    font-size: 1.6rem;
  }
  h3 {
    font-size: 1.3rem;
  }
  p {
    font-size: 0.9rem;
  }
  .container, .card {
    flex-direction: column;
  }
  img {
    margin-top: 70px;
  }
}